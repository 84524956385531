import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/LP/navbar';
import Footer from './components/LP/footer';
import LandingPage from './components/landingpage';
import CompetencesPage from './components/competencespage';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HonorairePage from './components/honorairepage';
import ActualitePage from './components/actualitespage';
import logoWhite from './components/LP/assets/logo.png';
import CabinetPage from './components/cabinetpage';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebaseconfig';

// Custom Hook for Firebase Analytics
const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Log page view
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });

    console.log(`Navigated to ${location.pathname}`);
  }, [location]);
};

const AppContent = () => {
  useAnalytics(); // Use analytics within the Router context

  return (
    <>
      <Navbar textColor="var(--white)" logo={logoWhite} />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/competences" element={<CompetencesPage />} />
        <Route path="/cabinet" element={<CabinetPage />} />
        <Route path="/honoraires" element={<HonorairePage />} />
        <Route path="/actualites" element={<ActualitePage />} />
      </Routes>
      <Footer />
    </>
  );
};

function App() {
  return (
    <Router>
      <main>
        <AppContent />
      </main>
    </Router>
  );
}

export default App;
