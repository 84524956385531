import React from 'react';
import './News.css';
import newsImage1 from './assets/placeholderArticles1.png'; // Replace with the actual path of your images
import newsImage2 from './assets/placeholderArticles2.jpg';
import { useNavigate } from 'react-router-dom';

const News = () => {
  const newsItems = [
    {
      image: newsImage1,
      title: "A venir...",
      description: "",
      date: "",
    },
    {
      image: newsImage2,
      title: "A venir...",
      description: "",
      date: "",
    },
  ];
  const navigate = useNavigate();
  const handleNavigationNewsPage = () => {
    navigate('./actualites')
  }

  return (
    <section className="news">
      <h2 className="news-title">Actualités</h2>
      <div className="news-grid">
        {newsItems.map((news, index) => (
          <div key={index} className="news-card" onClick={handleNavigationNewsPage} style={{ cursor: 'pointer' }}>
            <img src={news.image} alt={news.title + "Camille Charles Avocat 06"} className="news-image" />
            <div className="news-content">
              <h3 className="news-card-title">{news.title}</h3>
              <p className="news-description">{news.description}</p>
              <div className="news-footer">
                <span className="news-date">📅 {news.date}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleNavigationNewsPage} className="news-button">Toutes les actualités </button>
    </section>
  );
};

export default News;
