import React from 'react';
import './RendezVous.css';
import profileImage from './assets/profile.jpg'; // Replace with the actual path of your image
import { logEvent } from 'firebase/analytics'; // Import logEvent
import { analytics } from '../../firebaseconfig'; // Import the initialized analytics

const RendezVous = () => {
  const redirectAllaw = () => {
    // Trigger the analytics event
    logEvent(analytics, 'allaw_redirect', {
      button_name: 'Prendre Rendez-vous',
      destination_url: 'https://allaw.fr/avocat/Camille_CHARLES_4e4',
    });

    // Redirect the user
    window.open("https://allaw.fr/avocat/Camille_CHARLES_4e4", "_blank");
  };

  return (
    <section id="rendez-vous" className="rendez-vous">
      <div className="profile-image-container">
        <img src={profileImage} alt="Camille Charles Avocat 06" className="profile-image" />
        <div className="offset-border"></div>
        <div className="social-links">
          <a
            href="https://www.linkedin.com/in/camille-charles-2a011b86/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            in
          </a>
          <a
            href="https://maps.app.goo.gl/sAQRHM48oyZMNoYv6"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            G
          </a>
        </div>
      </div>
      <div className="profile-content">
        <h2 className="profile-name">Camille Charles</h2>
        <div className="underline"></div>
        <p className="profile-description">
          “Ma stratégie juridique repose sur l'équilibre entre audace et prudence : je protège vos intérêts tout en
          prenant des décisions éclairées.”
        </p>
        <button onClick={redirectAllaw} className="rendez-vous-button">
          Prendre Rendez-vous
        </button>
        <p>
          <i className="bi bi-envelope-fill icon"></i> camille@charles-avocats.com
        </p>
        <p>
          <i className="bi bi-telephone-fill icon"></i> 06 17 05 26 09
        </p>
      </div>
    </section>
  );
};

export default RendezVous;
