import React from 'react';
import './Header.css';
import background from './assets/background.jpg'; // Path to your background PNG

const Header = ({ scrollToContact }) => {
  return (
    <header className="header" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/portolio-1-f.appspot.com/o/Perso%2FWEB%20Camille%20101024.jpg?alt=media&token=8fe3f21e-324c-48ed-bfa5-df84e0ac6c7d)` }}>
      <div className="header-content">
        <h1>
          <span className="highlight">L’innovation</span> mérite une protection
          à la hauteur de vos <span className="highlight">ambitions</span>
        </h1>
        <p className="subtext">
          Expertise en propriété intellectuelle <br />
          et droit numérique.
        </p>
        <button onClick={scrollToContact} className="contact-cta">
          Me Contacter
        </button>
      </div>
    </header>
  );
};

export default Header;
