import React from 'react';
import { useLocation, Link } from 'react-router-dom'; // Import Link and useLocation
import './Footer.css';
import logoImage from './assets/logoblack.png'; // Replace with the actual path to your logo image

const Footer = () => {
  const location = useLocation();

  // Function to check if the link is active
  const isActive = (hash) => location.hash === hash;

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Camille Charles Avocat 06 Logo" className="footer-logo" />
        </Link>

        </div>
        <nav className="footer-nav">


          <a href="../cabinet" className={location.pathname === '/cabinet' ? 'active-link' : ''}>
            Le Cabinet
          </a>
          <a href="../competences" className={location.pathname === '/competences' ? 'active-link' : ''}>
            Compétences
          </a>
          <a href="../honoraires" className={location.pathname === '/honoraires' ? 'active-link' : ''}>
            Honoraires
          </a>
          <a href="../actualites" className={location.pathname === '/actualites' ? 'active-link' : ''}>
            Actualités
          </a>
          <a
            href="/Mentions_legales_CC.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={isActive('#mention-legales') ? 'active-link' : ''}
          >
            Mentions Légales
          </a>
          <a
            href="/path-to-confidentialite.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={isActive('#confidentialite') ? 'active-link' : ''}
          >
            Confidentialité
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
