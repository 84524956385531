import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GoogleCarousel.css';
import InfiniteCarousel from 'react-leaf-carousel';
import { RotatingLines } from 'react-loader-spinner';

const GooglePlaceDetails = () => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (screenWidth <= 700) {
        setSlidesToShow(1);
      } else if (screenWidth <= 1000) {
        setSlidesToShow(2);
      } else if (screenWidth <= 1430) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(3);
      }
    };

    updateSlidesToShow();
  }, [screenWidth]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const searchResponse = await axios.get(
          'https://us-central1-portolio-1-f.cloudfunctions.net/api/api/search-place',
          {
            params: {
              input: 'Camille Charles Avocat',
              language: 'fr', // Request in French
            },
          }
        );
        const placeId = searchResponse.data[0]?.place_id;

        if (!placeId) {
          console.error('Place ID not found');
          setLoading(false);
          return;
        }

        const detailsResponse = await axios.get(
          'https://us-central1-portolio-1-f.cloudfunctions.net/api/api/place-details',
          {
            params: {
              place_id: placeId,
              language: 'fr', // Request in French
            },
          }
        );

        const filteredReviews = detailsResponse.data.reviews.filter(
          (review) => review.rating > 4
        );

        setPlaceDetails({ ...detailsResponse.data, reviews: filteredReviews });
      } catch (error) {
        console.error('Error fetching place details:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();

    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const adjustCardHeights = () => {
      const cards = document.querySelectorAll('.review-card');
      let maxHeight = 0;
  
      cards.forEach((card) => {
        card.style.height = 'auto'; // Reset height to measure accurately
        maxHeight = Math.max(maxHeight, card.offsetHeight);
      });
  
      cards.forEach((card) => {
        card.style.height = `${maxHeight}px`; // Set all cards to max height
      });
    };
  
    adjustCardHeights();
    window.addEventListener('resize', adjustCardHeights);
  
    return () => {
      window.removeEventListener('resize', adjustCardHeights);
    };
  }, []);
  
  if (loading) {
    return (
      <div className="loader-container" style={{ display: 'flex', justifyContent: 'center' }}>
        <RotatingLines
          strokeColor="lightblue"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  if (!placeDetails || !placeDetails.reviews || placeDetails.reviews.length === 0) {
    return <p>No reviews available at the moment.</p>;
  }

  return (
    <section className="google-reviews-section">
      <h2 className="section-title">Mes derniers clients</h2>
      <InfiniteCarousel
        key={slidesToShow}
        sidesOpacity={0.5}
        sideSize={0.1}
        slidesToScroll={1}
        slidesToShow={slidesToShow}
        scrollOnDevice={true}
        autoCycle={false}
        cycleInterval={5000}
      >
        {placeDetails.reviews.map((review, index) => (
          <div key={index} className="review-card" style={{ cursor: 'pointer' }}>
            <div className="review-header">
              <div className="wrapper-review-header">
                <img
                  src={review.profile_photo_url || 'default-avatar.png'}
                  alt={review.author_name + "Camille Charles Avocat 06"}
                  className="review-avatar"
                />
                <div className="review-author">{review.author_name}</div>
                <div className="review-time">{review.relative_time_description}</div>
              </div>
              <div className="review-rating">
                {Array.from({ length: review.rating }, (_, i) => (
                  <span key={i} className="star star-red">
                    ★
                  </span>
                ))}
              </div>
            </div>
            <div className="review-line"></div>
            <p className="review-comment">"{review.text || 'No comment provided'}"</p>
          </div>
        ))}
      </InfiniteCarousel>
    </section>
  );
};

export default GooglePlaceDetails;
