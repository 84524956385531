import React from 'react';
import './Contact.css';
import logoallaw from './assets/logoallaw.png';
import { logEvent } from 'firebase/analytics'; // Import logEvent
import { analytics } from '../../firebaseconfig';

const Contact = () => {
  console.log("Map center: 16 Avenue Saint Nicolas, Cannes");

  // Function to open Allaw URL in a new tab and log analytics
  const redirectAllaw = () => {
    // Log analytics event
    logEvent(analytics, 'allaw_redirect', {
      button_name: 'Prendre Rendez-vous',
      destination_url: 'https://allaw.fr/avocat/Camille_CHARLES_4e4',
    });

    // Redirect the user
    window.open("https://allaw.fr/avocat/Camille_CHARLES_4e4", "_blank");
  };

  return (
    <section id="contact-section" className="contact">
      <div className="contact-info">
        <h2 className="contact-title">Contactez<span className="highlight">-moi</span></h2>
        <div className="contact-details">
          <p>
          <span className="material-symbols-outlined" style={{ color: '#007BFF' }}>
              deskphone
            </span>09 83 52 26 20 &nbsp;&nbsp; 
           
            <i className="bi bi-telephone-fill icon"></i> 06 17 05 26 09
          </p>
          <p><i className="bi bi-envelope-fill icon"></i> camille@charles-avocats.com</p>
          <p><i className="bi bi-geo-alt-fill icon"></i> 16 Avenue Saint Nicolas 06400 CANNES</p>
        </div>
        <img src={logoallaw} alt="Camille Charles Avocat Allaw Avocat 06" className="allaw-logo" />
        <button onClick={redirectAllaw} className="contact-button" id='rendezVousAllaw'>Prendre Rendez-vous</button>
      </div>
      <div className="map-container">
        <div className="offset-border-reverse"></div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.413224626725!2d7.016377676171709!3d43.55627197110689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd15ea378f961%3A0x774346cf232a0f1a!2sCamille%20Charles%20Avocat!5e0!3m2!1sfr!2sfr!4v1733743990397!5m2!1sfr!2sfr"
          title="Google Map Location"
          loading="lazy"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
